import React, { useRef, useEffect, useState } from 'react';
import $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import slider1 from '../../Assets/images/slide1.png';
import slider2 from '../../Assets/images/slide2.png';
import slider3 from '../../Assets/images/slide3.png';
import '../../Assets/css/style.css';
import '../../Assets/css/responsive.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Slider() {
    const myRef = useRef(null);
  return (
    <>
    <div className="others">
        <div className="wrapper"><h3  id='headslide'>Others You Might Like</h3>
            <span  className='headslidep'>We have topnotch products according to your taste. </span>
            <p  className='headslidepx'>So it will help you according to your desire.</p>
        </div>
	</div>
    <div className="related_slider">
        <OwlCarousel className='owl-carousel owl-theme related' loop margin={40} nav dots items={3.2} 
         navText={["Previous", "Next"]}
        responsive={{
            0:{
                items:1.2,
                nav:true,
                margin: 10,
            },
            600:{
                items:2.5,
                nav:false,
                margin: 20,
            },
            1100:{
                items:3.2,
                nav:true,
                loop:false
            }
            }}> 
            <div className="item" style={{borderRadius:15}}>
                <div className="item_box">
                    <img src={slider1} alt='' />
                </div>
            </div>
            <div className="item" style={{borderRadius:15}}>
                <div className="item_box">
                    <img src={slider2} alt='' />
                </div>               
            </div>
            <div className="item" style={{borderRadius:15}}>
                <div className="item_box">
                    <img src={slider3} alt='' />
                </div>
            </div>
            <div className="item" style={{borderRadius:15}}>
                <div className="item_box">
                    <img src={slider1} alt='' />
                </div>
            </div>
            <div className="item" style={{borderRadius:15}}>
                <div className="item_box">
                    <img src={slider2} alt='' />
                </div>
            </div>
            </OwlCarousel>

            
        </div>
    </>
  )
}

export default Slider