import React, { useRef, useEffect, useState } from 'react';
import '../../Assets/css/style.css';
import '../../Assets/css/responsive.css';
import banner1 from '../../Assets/images/banner1.png';
import bn from '../../Assets/images/bn.png';
import bgbanner from '../../Assets/images/bg-banner.png';
import Radium, {StyleRoot} from 'radium';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Navbar from '../Com/Navbar';
import styled, { keyframes } from "styled-components";
import '../../Assets/css/style.css';
import '../../Assets/css/responsive.css';
import imgicon from '../../Assets/images/iconSearch.png';
import logo from '../../Assets/images/logo.png';
import hd1 from '../../Assets/images/hd1.png';
import hd2 from '../../Assets/images/hd2.png';
import hd3 from '../../Assets/images/hd3.png';
import hd4 from '../../Assets/images/hd4.png';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from 'react-router-dom';

// Import the Owl Carousel library
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import 'owl.carousel';
import { Container } from 'react-bootstrap';
import Video1 from './Video1';

function Banner() {
    const buttonRef = useRef(null);
    useEffect(() => {
        const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log('button opened');
        } else {
            mousemovefn()
            console.log('button closed');
        }
      });
    }, options);

    const button = buttonRef.current;

    if (button) {
      observer.observe(button);
    }

    return () => {
      if (button) {
        observer.unobserve(button);
      }
    };
  }, []);
    useEffect(() => {
      function handleEscapeKey(event) {
        if (event.keyCode === 27) {
          setVisible(false);
        }
      }
  
      document.addEventListener('keydown', handleEscapeKey);
  
      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }, []);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
  
    useEffect(() => {
      // perform a search using the searchTerm
      // and update the searchResults state
      // You can implement this using an API call or any other search mechanism
      // Here is an example of hardcoding some search results
      const results = [
        "Evoko",
        "Origin Aquatic",
        "Aurora",
        "Avocar",
        "Draper",
        "Lutroin",
        "Playpp",
        "Sms",
        "Urc"
      ];
      const filteredResults = results.filter((result) =>
        result.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }, [searchTerm]);
  
    function handleInputChange(event) {
      setSearchTerm(event.target.value);
    }

    const [is_Open, setIs_open] = useState(false);
    const [Open, setOpen] = useState(false);
    const [Openp, setOpenp] = useState(false);
    const [visible, setVisible] = useState(false);
   
    const ToggleSidebarclose = () => {
        is_Open === true ? setIs_open(false) : setIs_open(false);
    }
    const enablefun=()=>{
        Openp=== true?setOpenp(false):setOpenp(false);
        Open=== false?setOpen(true):setOpen(true);
        visible===false?setVisible(false):setVisible(false);
    }
    const enablep=()=>{
        Open=== true?setOpen(false):setOpen(false);
        Openp=== false?setOpenp(true):setOpenp(true);
        visible===false?setVisible(false):setVisible(false);
    }
    function handleclose(){
        Openp=== true?setOpenp(false):setOpenp(false);
        Open=== true?setOpen(false):setOpen(false);
        visible===false?setVisible(false):setVisible(false);
    }
    function opensearch(){
        Openp=== true?setOpenp(false):setOpenp(false);
        Open=== true?setOpen(false):setOpen(false);
        visible===false?setVisible(true):setVisible(false);
    }
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsFilled(true);
    }, 500);
    var splash = document.getElementById("v");
    setTimeout(function(){
      splash.classList.add("cool-effect");
    }, 1500);
  }, []);
  const mousemovefn=()=>{
    ToggleSidebarclose()
    handleclose()
}

  return (
    <>
    <Navbar/>
    {/* <div className="banner" onClick={mousemovefn} onMouseMove={mousemovefn}>      
        <div className='banner_cover' onClick={ToggleSidebarclose} onMouseMove={ToggleSidebarclose}>
          <div style={{textAlign:'center',width:'100%'}} >
            <h2 className={`text ${isFilled ? 'fill' : ''}`}
            data-fill-text="Ready" style={{margin:'auto'}}>Ready</h2><span>&nbsp;&nbsp;</span>
            <h2 className={`text ${isFilled ? 'fill' : ''}`}
            data-fill-text="For" style={{margin:'auto'}}>For</h2><span>&nbsp;&nbsp;</span>
            <h2 className={`text ${isFilled ? 'fill' : ''}`}
            data-fill-text="the" style={{margin:'auto'}}>the</h2><span>&nbsp;&nbsp;</span>
             <h2 className={`text ${isFilled ? 'fill' : ''}`}
            data-fill-text="next" style={{margin:'auto'}}>Next</h2>

          </div>
          <div className='mt-2' style={{textAlign:'center',width:'100%',display:'flex',gap:'55px'}}>
            <h2 className={`textH2 ${isFilled ? 'fill' : ''}`}
            data-fill-text="Big">Big</h2>
            <h2 data-fill-text="Thing" className={`textH3 ${isFilled ? 'fill' : ''}`}>Thing</h2>
            <h2 data-fill-text="?" className={`textH3 ${isFilled ? 'fill' : ''}`}>?</h2>
         </div>
          
          
         </div>
         <img src={bgbanner} className="bg-pos"></img>          
	</div> */}

  <div className="banner">
			<div className="banner_cover">
				<h3>Ready for the next</h3>
				<div className="po_im">
				    <img src={banner1} className="web_header1" />
				    <div className='t' id="v">
    				<img src={bn} className="web_header1"/>
    				</div>
				</div>
				
			</div>
			<img src={bgbanner} className="bg-pos"/>
		</div>
    <div onClick={mousemovefn} onMouseMove={mousemovefn}>
        <Video1/>
    </div>
    </>
  )
}

export default Banner