import React,{useState} from 'react';
import mail from '../../Assets/images/mail.png';
import social1 from '../../Assets/images/social1.png';
import social2 from '../../Assets/images/social2.png';
import social3 from '../../Assets/images/social3.png';
import social4 from '../../Assets/images/social4.png';
import social5 from '../../Assets/images/social5.png';
import social6 from '../../Assets/images/social6.png';
import l from '../../Assets/images/l.png';
import w from '../../Assets/images/w.png';
import f from '../../Assets/images/f.png';
import i from '../../Assets/images/i.png';
import t from '../../Assets/images/t.png';
import y from '../../Assets/images/y.png';
import linkedin from '../../Assets/images/linkedin-white.svg';
import linkedin_color from '../../Assets/images/linkedin-color.svg';
import facebook from '../../Assets/images/facebook-whiute.svg';
import facebook_color from '../../Assets/images/facebook-color.svg';
import youtube from '../../Assets/images/youtube-white.svg';
import youtube_color from '../../Assets/images/youtube-color.svg';
import whatsap from '../../Assets/images/whatsapwhite.svg';
import whatsap_color from '../../Assets/images/whatsap-color.svg';
import instagram from '../../Assets/images/instagram-white.svg';
import insta_color from '../../Assets/images/insta-color.svg';
import twitter from '../../Assets/images/twitter-white.svg';
import twittrrer_color from '../../Assets/images/twittrrer-color.svg';

function Footer() {
	const [hovered, setHovered] = useState(false);
	const [hovered2, setHovered2] = useState(false);
	const [hovered3, setHovered3] = useState(false);
	const [hovered4, setHovered4] = useState(false);
	const [hovered5, setHovered5] = useState(false);
	const [hovered6, setHovered6] = useState(false);
	const handleMouseOver = () => {setHovered(true); }	
	const handleMouseOut = () => {setHovered(false); }
	const handleMouseOver2 = () => {setHovered2(true); }	
	const handleMouseOut2 = () => {setHovered2(false); }
	const handleMouseOver3 = () => {setHovered3(true); }	
	const handleMouseOut3 = () => {setHovered3(false); }
	const handleMouseOver4 = () => {setHovered4(true); }	
	const handleMouseOut4 = () => {setHovered4(false); }
	const handleMouseOver5 = () => {setHovered5(true); }	
	const handleMouseOut5 = () => {setHovered5(false); }
	const handleMouseOver6 = () => {setHovered6(true); }	
	const handleMouseOut6 = () => {setHovered6(false); }
  return (
    <>
			<div className="footer">
				<div className="footer_wrapper">
					<div className="footer_flex">
						<div className="footer_menu">
							<ul>
								<li className='hoverhand'>Homepage</li>
								<li className='hoverhand'>About Us</li>
								<li className='hoverhand'>Contact Us</li>
								<li className='hoverhand'>Terms and Privacy</li>
							</ul>
							<ul>
								<li className='hoverhand'>Careers</li>
								<li className='hoverhand'>Blogs & Case Studies</li>
								<li className='hoverhand'>News & Events</li>
								<li className='hoverhand'>Training</li>

							</ul>
							<ul>
								<li className='hoverhand'>Brands</li>
								<li className='hoverhand'>Solutions</li>
								<li className='hoverhand'>Products</li>
								<li className='hoverhand'>Client Reviews</li>

							</ul>
							<ul>
								<li className='hoverhand'>Awards</li>
								<li className='hoverhand'>Email</li>
								<li className='hoverhand'>Newsletter</li>
								<li className='hoverhand'>Cookies and Policy</li>

							</ul>

						</div>
						<div className="footer_right">
							<h2>The Start of a New Journey</h2>
						</div>
					</div>


					<div className="footer_bottom">
						<div className="copyright">
							<p>Evig Copyright © 2023. All rights reserved.</p>
						</div>
						<div className="connect">
							<h3>Connect with us</h3>
							<ul>
								<li>
									<img src={hovered?linkedin_color:linkedin} alt='' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}/>
								</li>
								<li>
									<img src={hovered2?youtube_color:youtube} alt='' onMouseOver={handleMouseOver2} onMouseOut={handleMouseOut2}/>
								</li>
								<li>
									<img src={hovered3?whatsap_color:whatsap} alt='' onMouseOver={handleMouseOver3} onMouseOut={handleMouseOut3}/>
								</li>
								<li>
									<img src={hovered4?facebook_color:facebook} alt='' onMouseOver={handleMouseOver4} onMouseOut={handleMouseOut4}/>
								</li>
								<li>
									<img src={hovered5?insta_color:instagram} alt='' onMouseOver={handleMouseOver5} onMouseOut={handleMouseOut5}/>
								</li>
								<li>
									<img src={hovered6?twittrrer_color:twitter} alt='' onMouseOver={handleMouseOver6} onMouseOut={handleMouseOut6}/>
								</li>
							</ul>
						</div>


					</div>

					

				</div>

			</div>
            </>

  )
}

export default Footer