import React, { useRef, useEffect, useState } from 'react';
import vid from '../../Assets/videos/v1.mp4';

function Video1() {
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.play();
          console.log('video 1 play');
        } else {
          entry.target.pause();
          console.log('video 1 poused');
        }
      });
    }, options);

    const video = videoRef.current;

    if (video) {
      observer.observe(video);
    }

    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }, []);
    const handleVideoError = () => {
      console.error('Video cannot be played');
    };
  //   const [isLoading, setIsLoading] = useState(true);

  // function handleLoadStart() {
  //   setIsLoading(true);
  // }

  // function handleCanPlayThrough() {
  //   setIsLoading(false);
  // }
  return (
    <>
    <div style={{ width: '100%',height:'100%' }}>
      <div className="video video1 pos_sec height-sec">  
        {/* {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Loading...
          </div>
        )} */}
        <video
        loop autoplay="autoplay" playsInline muted ref={videoRef}
          onError={handleVideoError}
        >
        <source src={vid} type="video/mp4" />
        Your browser does not support HTML5 video.
        </video>
      </div>
      </div>
      {/* <div style={{ width: '100%' }} className='d-md-none'>
      <div style={{ position: "relative" }}>  
        <video
          ref={videoRef}
          width="100%"
          height="360"
          autoPlay
          muted
          loop
          onCanPlay={() => videoRef.current.play()}
          onError={handleVideoError}
        >
        <source src={vid} type="video/mp4" />
        Your browser does not support HTML5 video.
        </video>
      </div>
      </div> */}
      </>
  )
}

export default Video1