import React,{ useRef } from 'react';
import '../../Assets/css/style.css';
import '../../Assets/css/responsive.css';
import imgicon from '../../Assets/images/iconSearch.png';
import logo from '../../Assets/images/logo.png';
import hd1 from '../../Assets/images/hd1.svg';
import hd2 from '../../Assets/images/hd2.svg';
import hd3 from '../../Assets/images/hd3.svg';
import hd4 from '../../Assets/images/hd4.svg';
import hd5 from '../../Assets/images/hd5.svg';
import hd6 from '../../Assets/images/hd6.svg';
import hd7 from '../../Assets/images/hd7.svg';
import hd8 from '../../Assets/images/hd8.svg';
import hd9 from '../../Assets/images/hd9.svg';
import hd10 from '../../Assets/images/hd10.svg';
import hd11 from '../../Assets/images/hd11.svg';
import hd12 from '../../Assets/images/hd12.svg';
import hd13 from '../../Assets/images/hd13.svg';
import { useEffect, useState } from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Import the Owl Carousel library
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import 'owl.carousel';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';



function Navbar() {
    const buttonRef = useRef(null);
    useEffect(() => {
        const container = document.getElementById("mob_header")
        const rect1 = container.getBoundingClientRect()
        const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log('button opened');
        } else {
            mousemovefn()
            console.log('button closed');
        }
      });
    }, options);

    const button = buttonRef.current;

    if (button) {
      observer.observe(button);
    }

    return () => {
      if (button) {
        observer.unobserve(button);
      }
    };
    
  }, []);
  document.addEventListener('scroll', function(e) {
    mousemovefn()
  });
    useEffect(() => {
      function handleEscapeKey(event) {
        if (event.keyCode === 27) {
          setVisible(false);
        }
      }
  
      document.addEventListener('keydown', handleEscapeKey);
  
      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }, []);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
  
    useEffect(() => {
      // perform a search using the searchTerm
      // and update the searchResults state
      // You can implement this using an API call or any other search mechanism
      // Here is an example of hardcoding some search results
      const results = [
        "Evoko",
        "Origin Aquatic",
        "Aurora",
        "Avocar",
        "Draper",
        "Lutroin",
        "Playpp",
        "Sms",
        "Urc"
      ];
      const filteredResults = results.filter((result) =>
        result.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }, [searchTerm]);
  
    function handleInputChange(event) {
      setSearchTerm(event.target.value);
    }

    const [is_Open, setIs_open] = useState(false);
    const [Open, setOpen] = useState(false);
    const [Openp, setOpenp] = useState(false);
    const [visible, setVisible] = useState(false);
    // const handleButtonClick = (e) => {
    //   $(e.target).closest(".header-flex").toggleClass("show_mob_menu");
    // };
    const ToggleSidebar = () => {
        is_Open === true ? setIs_open(false) : setIs_open(true);
    }
    const ToggleSidebarclose = () => {
        is_Open === true ? setIs_open(false) : setIs_open(false);
    }
    const enablefun=()=>{
        Openp=== true?setOpenp(false):setOpenp(false);
        Open=== false?setOpen(true):setOpen(true);
        visible===false?setVisible(false):setVisible(false);
    }
    const enablep=()=>{
        Open=== true?setOpen(false):setOpen(false);
        Openp=== false?setOpenp(true):setOpenp(true);
        visible===false?setVisible(false):setVisible(false);
    }
    function handleclose(){
        Openp=== true?setOpenp(false):setOpenp(false);
        Open=== true?setOpen(false):setOpen(false);
        visible===false?setVisible(false):setVisible(false);
    }
    function opensearch(){
        Openp=== true?setOpenp(false):setOpenp(false);
        Open=== true?setOpen(false):setOpen(false);
        visible===false?setVisible(true):setVisible(false);
    }
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsFilled(true);
    }, 500);
  }, []);
  const mousemovefn=()=>{
    ToggleSidebarclose()
    handleclose()
}
  
  return (
    <>
       <div>
        <div className="herader_main web_header">
            <div className="header_wrapper">
                <div className="header-flex">
                    <div className='divHover'>
                        <div className="button_line hoverhand">
                            <div  >
                                <div className="half-c" >
                                    <span></span>
                                    <span></span>
                                </div>
                                <span ></span>
                                <span></span>            
                            </div>
                            {/* <h4 ref={buttonRef}  className={is_Open?'mb-0 hoverhand _close':'mb-0 hoverhand _open'} style={{marginLeft:20,color:'#D6D6D6',fontSize:'28px'}} onClick={ToggleSidebarclose}>X</h4> */}
                            {/* <div onMouseOver={ToggleSidebar}  ref={buttonRef}  className={is_Open?'mb-0 hoverhand _close':'mb-0 hoverhand _open'} onClick={ToggleSidebarclose}>
                                <div className="half-c" >
                                    <span></span>
                                    <span></span>
                                </div>
                                <span className='mt-1'></span>
                                <span className='mt-1'></span>            
                            </div> */}
                        </div>      
                        <div className='sidebar' >
                            <div className="sd-body">
                                <ul >
                                    <li className='hoverhand' style={{textAlign:'center',marginTop:'100px'}}>About Us</li>
                                    <li className='hoverhand' style={{textAlign:'center',marginTop:'100px'}}>News & Events</li>
                                    <li className='hoverhand' style={{textAlign:'center',marginTop:'100px'}}>Careers</li>
                                    <li className='hoverhand' style={{textAlign:'center',marginTop:'100px'}}>Blog & Case Studies</li>
                                    <li className='hoverhand' style={{textAlign:'center',marginTop:'100px'}}>Contact Us</li>
                                    {/* <li className='hoverhand' style={{textAlign:'center',margin:'60px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>Contact Us</a></li> */}

                                </ul>
                            </div>
                        </div>             
                    </div>
                    <div className='spacer'></div>
                    <div className="header_menu" onClick={ToggleSidebarclose}>
                        <ul>
                        <li className='' ><Link style={{textDecoration:'none'}}>Home</Link></li>
                        <li className='onhover hoverhand'  ><span>Brands</span>
                        <div className="header_bottom_brand" >
                    <div className="header_wrapper">
                        <div className="header_bottom_flex">
                            <div className="bt_box_brand hoverhand">
                                <p>EVOKO</p>
                            </div>
                            <div className="bt_box_brand hoverhand">
                                <p>ORIGIN ACOUSTICS</p>
                            </div>
                            <div className="bt_box_brand hoverhand">
                                <p>AURORA</p>
                            </div>
                            <div className="bt_box_brand hoverhand">
                                <p>AVOCOR</p>
                            </div>
                            <div className="bt_box_brand hoverhand">
                                <p>DRAPER</p>
                            </div>
                            <div className="bt_box_brand hoverhand">
                                <p>LUTRON</p>
                            </div>
                            <div className="bt_box_brand hoverhand">
                                <p>PLAYIPP</p>
                            </div>
                            <div className="bt_box_brand hoverhand">
                                <p>SMS</p>
                            </div>
                            <div className="bt_box_brand">
                                <p>URC </p>
                            </div>
                            <div className="bt_box_brand">
                                <p>LOUD OF SWEDEN</p>
                            </div>
                        </div>
                    </div>
                </div></li>
                        <li className='onhover hoverhand' ><span>Products</span>
                        <div className="header_bottom" >
                <div className="header_wrapper">
                    <div className="header_bottom_flex">
                    <OwlCarousel className='owl-carousel owl-theme related'  margin={100} 
                    responsive={{
                            0: {
                            items: 3,
                            margin: 60 ,
                            },
                            768: {
                            items: 5,
                            margin: 50 ,
                            },
                            992: {
                            items: 5,
                            margin: 50 ,
                            },
                            1200: {
                                items: 7,
                                margin: 80 ,
                             },
                            1600: {
                                items: 9,
                                margin: 100 ,
                             }
                        }}> 
                        <div className="bt_box hoverhand " >
                            <div className="bt_box_inner">
                                <img src={hd1} alt=''/>
                            </div>                        
                            <p>ROOM BOOKING <br/>SYSTEM</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd2} alt=''/>
                            <p>DESK BOOKING<br/>SYSTEM</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd3} alt=''/>
                            <p>WIRED & WIRELESS <br/>PRESENTER</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd4} alt=''/>
                            <p>DISPLAY MOUNTS <br/>& STANDS </p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd5} alt=''/>
                            <p>INTERACTIVE DISPLAYS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd6} alt=''/>
                            <p>SPEAKERS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd7} alt=''/>
                            <p>PROJECTOR LIFT<br/>& MOUNTS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd8} alt=''/>
                            <p>PROJECTOR SCREENS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd9} alt=''/>
                            <p>AV OVER IP<br/>SOLUTIONS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd10} alt=''/>
                            <p>BUILDING<br/> AUTOMATION</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd11} alt=''/>
                            <p>SMART LIGHTING<br/>CONTROL</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd12} alt=''/>
                            <p>WINDOW SHADES</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd13} alt=''/>
                            <p>DIGITAL SINAGE</p>
                        </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
                        </li>
                       <li className='' onMouseOver={handleclose}>  <Link style={{textDecoration:'none'}}><span>Solutions</span></Link></li>
                       <li className='' onMouseOver={handleclose}>  <Link style={{textDecoration:'none'}}><span>Customers</span></Link></li>
                       <li className='' onMouseOver={handleclose}>  <Link style={{textDecoration:'none'}}><span>Awards</span></Link></li>

                        </ul>
                        <div class="srch">
                            <img className='hoverhand' src={imgicon} onClick={opensearch}/>
                        </div>
                    </div>
                    <div className="logo_main">
                        <img className='hoverhand' src={logo} alt=''/>
                    </div>
                </div>
            </div>
            <div className="header_bottom_dark" style={{display:visible?'block':'none'}}>
                <div className="header_wrapper" style={{padding:30}}>
                <Container>
                    <div className='row'>
                        <div className='col-md-1'></div>
                        <div className='col-md-10'>
                            <div className='row'>
                                <input className='hoverhand' type="text" id="fnamesearch" value={searchTerm} onChange={handleInputChange} name="fname" style={{border:'none'}} placeholder='Search eviggroup.com'/>
                            </div>
                            <div className='row mt-4'>
                                <h2 className='sugctn'>Suggestions:</h2>
                                <ul className='sugulli' style={{height:'90px',overflow:'hidden'}}>
                                    {searchResults.map((result) => (
                                    <li className='hoverhand' key={result} style={{listStyleType:'none',color:'white'}}>{result}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-1 hoverhand' onClick={opensearch} style={{fontSize:'20px',color:'white'}}>x</div>
                    </div>
                    </Container> 
                </div>
            </div>
           
               
        </div>
        <div className="herader_main mobile_header" id='mob_header'>
            <div className="header_wrapper" >
                <div className="header_flex_bottom">
                    
                </div>
                <div className="header-flex">
                    <div className="srch">
                            <img src={imgicon} alt=''  onClick={opensearch}/>
                            <div className="lang">
                                <span className='hoverhand'>En</span>
                                <span className='hoverhand'>عربی</span>
                            </div>
                        </div>
                    
                    <div className='logo_main_mb' ref={buttonRef}>
                        <img src={logo} alt=''/>
                    </div>
                        <div ref={buttonRef} onClick={ToggleSidebar}  className={is_Open?'_sideopen':'_sideclose'} >
                            <div className="button_line" > 
                            <div>                         
                                <div className="half-c" >
                                    <span></span>
                                    <span></span>
                                </div>
                                <span style={{width:'22px'}} ></span>
                                <span style={{width:'22px'}} ></span>
                                </div>  
                            </div>
                        {/* mobile sidebar start */}
                             <div className={`d-lg-none sidebarMob ${is_Open == true ? 'active' : ''}`} onMouseEnter={ToggleSidebarclose}>
                                <div className="sd-header_mb" style={{textAlign:'right'}}>
                                        <h4 className="mb-0 hoverhand" style={{marginRight:12,display:is_Open?'block':'none',color:'#D6D6D6',fontSize:'28px'}} onClick={ToggleSidebarclose}>X</h4>
                                </div>
                                <div className="sd-body-mb">
                                    <ul >
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>Home</a></li>
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>Brands</a></li>
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>Products</a></li>
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>Customers</a></li>
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>Awards</a></li>
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>About Us</a></li>
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>News and Events</a></li>
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>Careers</a></li>
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>Blogs</a></li>
                                        <li className='hoverhand' style={{textAlign:'right',margin:'10px'}}><a href="#" style={{color:'#D6D6D6',textAlign:'center'}}>Contact Us</a></li>

                                    </ul>
                                </div>
                        </div>
                          {/* mobile sidebar end */}
                        </div>

                    <div className="header_menu mob_menu">
                        <ul>
                        <Link style={{textDecoration:'none'}}><li className='hoverhand'>Home</li></Link>
                        <Link style={{textDecoration:'none'}}><li className='hoverhand'>Brands</li></Link>
                        <Link style={{textDecoration:'none'}}><li className='hoverhand'>Products</li></Link>
                        <Link style={{textDecoration:'none'}}><li className='hoverhand'>Solutions</li></Link>
                        <Link style={{textDecoration:'none'}}><li className='hoverhand'>Customers</li></Link>
                        <Link style={{textDecoration:'none'}}><li className='hoverhand'>Awards</li></Link>

                        </ul>
                        
                    </div>
                </div>


            </div>
            <div className="header_bottom_dark" style={{display:visible?'block':'none'}}>
                <div className="header_wrapper" style={{padding:30}}>
                <Container>
                    <div className='row'>
                        <div className='col-10'>
                            <div className='row'>
                                <input className='hoverhand' type="text" id="fnamesearch" value={searchTerm} onChange={handleInputChange} name="fname" style={{border:'none'}} placeholder='Search eviggroup.com'/>
                            </div>
                            <div className='row mt-4'>
                                <h2 className='sugctn'>Suggestions:</h2>
                                <ul className='sugulli' style={{height:'90px',overflow:'hidden'}}>
                                    {searchResults.map((result) => (
                                    <li className='hoverhand' key={result} style={{listStyleType:'none',color:'white'}}>{result}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='col-1 hoverhand' onClick={opensearch} style={{fontSize:'20px',color:'white'}}>x</div>
                    </div>
                    </Container> 
                </div>
            </div>

            <div className="header_bottom">
                <div className="header_wrapper">
                    <div className="header_bottom_flex">
                    <OwlCarousel className='owl-carousel owl-theme related' margin={10} 
        responsive={{
                0: {
                items: 3
                },
                768: {
                items: 3
                },
                992: {
                items: 6
                }
            }}> 
                        <div className="bt_box hoverhand " >
                            <div className="bt_box_inner">
                                <img src={hd1} alt=''/>
                            </div>                        
                            <p>ROOM BOOKING <br/>SYSTEM</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd2} alt=''/>
                            <p>DESK BOOKING<br/>SYSTEM</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd3} alt=''/>
                            <p>WIRED & WIRELESS <br/>PRESENTER</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd4} alt=''/>
                            <p>DISPLAY MOUNTS <br/>& STANDS </p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd5} alt=''/>
                            <p>INTERACTIVE DISPLAYS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd6} alt=''/>
                            <p>SPEAKERS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd7} alt=''/>
                            <p>PROJECTOR LIFT<br/>& MOUNTS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd8} alt=''/>
                            <p>PROJECTOR SCREENS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd9} alt=''/>
                            <p>AV OVER IP<br/>SOLUTIONS</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd10} alt=''/>
                            <p>BUILDING<br/> AUTOMATION</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd11} alt=''/>
                            <p>SMART LIGHTING<br/>CONTROL</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd12} alt=''/>
                            <p>WINDOW SHADES</p>
                        </div>
                        <div className="bt_box hoverhand">
                            <img src={hd13} alt=''/>
                            <p>DIGITAL SINAGE</p>
                        </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
       
       
    </div>
    </>
  )
}

export default Navbar