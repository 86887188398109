import React, { useRef, useEffect, useState } from 'react';
import bgbanner from '../../Assets/images/roomMngr.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import toast, { Toaster } from 'react-hot-toast';
import { useForm, Controller } from "react-hook-form";

function Content2() {
  const [show, setShow] = useState(false);
  const [verfied, setVerifed] = useState(false);
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    control,
    setValue,
    setFocus,
    reset,

    setError,
    clearErrors,
    values,
    trigger,
} = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
});


  //recaptcha function
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerifed(true);
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
      const onclick=()=>{
        if(verfied){
            toast.success('Submited Succesfully')
        }else{
          toast.error('Capcha Validation failed')
        }
      }
      const [positiveNumber, setPositiveNumber] = useState('');    
      function handlePositiveNumberChange(event) {
        const inputValue = event.target.value;
        if (Number(inputValue)>0) {
          setPositiveNumber(inputValue);
        } else {
          setPositiveNumber('');
        }
      } 
  return (
    <>
    <div className="content_2 room_manager pos_sec">
        <div className="self_hosted">
            <h4 className="container">A self hosted </h4>
            <h2 className="container">room manager</h2>
            <button type='button' id='bookbutn'  className='d-none d-lg-block' onClick={handleShow}>BOOK A DEMO</button>
            <button type='button' id='mobbtn' className='d-lg-none' onClick={handleShow}>BOOK A DEMO</button>
        </div>
        <img src={bgbanner} alt=''/>
    </div>
    <Modal show={show} onHide={handleClose} className='bookdem'>
      <Modal.Header >
			  <div className='hoverhand' style={{width:'100%',textAlign:'right',color:'white'}}>
			  <h1   onClick={handleClose}>X</h1></div>
        </Modal.Header>
        <Modal.Body>
        <div className="wrapper">
            <div className="popup-box">
            <div className="form-group mt-3">
            <form method="post" onSubmit={handleSubmit(onclick)} style={{textAlign:'center'}}>
              
              <Row>
                <Col>
                  <input type="text" className='subinput' id='subinput' name="firstname" required placeholder="Name"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="text" className='subinput' id='subinput' name="lastname" required placeholder="Last name"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="email" className='subinput' id='subinput' name="useremail-id" required placeholder="Email"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="text" className='subinput' id='subinput' value={positiveNumber}  onChange={handlePositiveNumberChange} name="mobile" required placeholder="Mobile"/>
                </Col>
              </Row>
              <Row className='mt-2 mb-2'>
                <Col>
                <textarea id='subinputarea' className='subinput' name="Message" placeholder="Message" required/>
                </Col>
              </Row>
                
              <ReCAPTCHA
          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          onChange={onChange}
        />
                <button type="submit"style={{backgroundColor:'black'}} id="subscribe">BOOK NOW</button>
            </form>
            </div>
            </div>
        </div>
        </Modal.Body>
        
      </Modal>
      <Toaster />
    </>
  )
}

export default Content2