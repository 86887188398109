import React, { useRef,useEffect,useState } from 'react';
import '../../Assets/css/style.css';
import '../../Assets/css/responsive.css';
import pdf from '../../Assets/images/pdf.png';
import getin from '../../Assets/images/getin.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import getgray from '../../Assets/images/get.png';
import getclr from '../../Assets/images/getin.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
function KeyFeatures() {
	const pdfLink = useRef(null);

	const handleDownload = () => {
	  pdfLink.current.click();
	};
	const myRef = useRef(null);
	const targetRef = useRef(null);
	  useEffect(() => {
		const box = document.querySelector('#gt1');
			document.addEventListener('scroll', function () {
				if(isInViewport(box)) {
					box.classList.add('cool-effect');
				}
				else {
					box.classList.remove('cool-effect');
				}
			
			});
		function isInViewport(el) {
			const rect = el.getBoundingClientRect();
			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		
			)
			
		}
		
		}, []);
		
  return (
    <>
    <div className="specs listing_sp">
				
				<div className="liso">
					

					<div className="icon_boxs">
						
						<div className="icon_box">
							<h3>KEY FEATURES</h3>
							<ul>
									<li>Configure as Transmitter (Encoder) or Receiver (Decoder) </li>
									<li>4K UHD over 1G CAT 5e/6/6a </li>
									<li>HDMI 2.0, HDCP 2.2 </li>
									<li>Visually Lossless with only 1.5 Frame Latency </li>
									<li>128 per Source with HDCP, Larger for Non-Encrypted Sources </li>
									<li>Video Wall with Image Rotation </li>
									<li>1G LAN PoE </li>
									<li>1 SFP for Single/Multimode Fiber or Second RJ-45 </li>
									<li>2 HDMI Inputs, 1 HDMI Output </li>
									<li>Line In/Out Stereo </li>
									<li>RS-232 Serial Port and IR (In/Out) </li>
									<li>On Screen Display (OSD) </li>
									<li>Integrated Web Server for Configuration </li>
									<li>USB 2.0 Host/Device Port </li>
									<li>2 USB 1.1 for HID Devices </li>
									<li>Dante® IP Option </li>
									<li>Front Keypad and IR Remote </li>
									<li>Rack and Under Table Mounting </li>
							</ul>
						</div>

						<div className="mob_footer show_in_web_">
							<h3>GUIDE FILE(S)</h3>

							<div className="pdf">
								<img src={pdf} alt='' onClick={handleDownload}/>
								<a
        href="/path/to/pdf-file.pdf"
        download
        ref={pdfLink}
        style={{ display: 'none' }}
      ></a>
								<span>Datasheet <br/> 103 kb</span>
							</div>

						</div>






					</div>

				</div>

			</div>
				{/* <div className='mail_s infomail' style={{textAlign:'center',width:'100%'}} ref={targetRef}>
          			<div className="im_w">
              			<img src={getgray} alt='' />            
						<div className='get_im' id="gt1">
							<img src={getclr} alt='' />
						</div>
					</div>
					<h2 id='hoveremail' >info@eviggroup.com</h2>            
				</div> */}
		<div className='infomail' ref={targetRef}>
			<div className='mail_s' style={{textAlign:'center',width:'100%'}} >
          		<div className="im_w">
              		<img src={getgray} alt='' />            
					<div className='get_im' id="gt1">
						<img src={getclr} alt='' />
					</div>
              	</div>
				<h2 id='hoveremail' >info@eviggroup.com</h2>
            
			</div>
		</div>
    </>
  )
}

export default KeyFeatures