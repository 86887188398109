import React, { useRef, useEffect, useState } from 'react';
import mail from '../../Assets/images/mail.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AOS from 'aos';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import 'aos/dist/aos.css';
import ReactReadMoreReadLess from "react-read-more-read-less";
import ReadMoreReact from 'read-more-react';
import { useForm, Controller } from "react-hook-form";
import getgray from '../../Assets/images/get.png';
import getclr from '../../Assets/images/getin.png';
gsap.registerPlugin(ScrollTrigger);
function Subscribe() {

  const { register, handleSubmit, getValues, formState: { errors } } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const myRef = useRef(null);
    const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    const handleShow = () => {
      show?setShow(false):setShow(true);
    };
    const targetRef = useRef(null);
		const [isFilled, setIsFilled] = useState(false);
		function handleIntersection(entries) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				setTimeout(() => {
					setIsFilled(true);
				  }, 1500);
			  } else {
				setIsFilled(false);
			  }
			});
		  }
		
		  useEffect(() => {
        const box = document.querySelector('#gt3');
          document.addEventListener('scroll', function () {
            if(isInViewport(box)) {
              box.classList.add('cool-effect');
            }
            else {
              box.classList.remove('cool-effect');
            }
          
          });
        function isInViewport(el) {
          const rect = el.getBoundingClientRect();
          return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        
          )
          
        }
        
        }, []);
      const onSubmit = data => {
    console.log(data);
  };
  const [positiveNumber, setPositiveNumber] = useState(0);    
      function handlePositiveNumberChange(event) {
        const inputValue = event.target.value;
        if (Number(inputValue)>0) {
          setPositiveNumber(inputValue);
        } else {
          setPositiveNumber('');
        }
      } 
  return (
    <>
        <div className='infomail_m d-md-none ' style={{backgroundColor:'black'}} ref={targetRef}>
					{/* <div className="infoanim" style={{wordSpacing:2}}>
						<h1 className={`mailtext ${isFilled ? 'fill' : ''}`}
						data-fill-text="Get in Touch!">Get in Touch!</h1>
					</div> */}
					<div className='mail_s' style={{textAlign:'center',width:'100%'}}>
            <div className='hm_im_block'>
              <img src={getgray} alt='' />
              
              <div className='get_im' id="gt3">
                <img src={getclr} alt='' />
              </div>
            </div>
						<h2 id='hoveremail' >info@eviggroup.com</h2>
					</div>
				</div>
    <div className="subscribe">
        <div className="subscripe_now_home">
            <h2 className="container">Be inspired, sign up now</h2>
            <p className="container mt-1">Never miss the latest offers from Evig, tips and demos,<br/> news and invites to local events.</p>
            <button className='mt-5' onClick={handleShow} style={{backgroundColor:show?'white':'transparent',color:show?'black':'white'}}>Subscribe Now</button>
        </div>

    </div>
    <div style={{display:show?'block':'none'}}>
    <div className="small_screen cform d-none d-lg-block" >
					<div className="footer_wrapper">
						<div className="ftitle">
							<h2>We are here <br/>for you anytime</h2>
						</div>
						<div className="fm_fld">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className="custom-field  one fm_flddiv">
                <input type="text" id='_fname_l' placeholder=" " className="subinputfield"
                 {...register('_fname_l', { required: true })}/>
                <span className="placeholder">Name{errors._fname_l &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._fname_l && <p className='mt-1 important'>Name is a mandatory field</p>}
              </label>
               <label className="custom-field  one fm_flddiv">
                <input type="text" id='_phone_l' placeholder=" " className="subinputfield"
                {...register("_phone_l", {
                  required: true,
                    pattern:{
                      value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                    },
                })}/>
                <span className="placeholder">Contact Number{errors._phone_l &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._phone_l && <p className='mt-1 important'>Please enter a contact number</p>}
              </label>
              <label className="custom-field  one fm_flddiv">
                <input type="text" id='_email_l' placeholder=" " className="subinputfield"
                {...register("_email_l", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                })}/>
                <span className="placeholder">Email{errors._email_l &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._email_l && <p className='mt-1 important'>Please enter a valid email address</p>}
              </label>
             
							<p className='field_p'>
              <ReadMoreReact min={70}
                ideal={90}
                max={200}
                readMoreText="Read more" text={'By signing up to the Evig Electronics you accept that Evig Electronics can contact...........'} /> 
							</p>
							<button type="submit">Subscribe Now</button>
              </form>
						</div>
					</div>

				</div>
    <div className='large_screen d-lg-none'>
      <div className='row'>
        <div className='col-12 ftitle_mob' style={{textAlign:'center'}}>
          <h2>We are here <br/>for you anytime</h2>
        </div>
        <div className='col-12'>
        <div className="fm_fld_mob">
        <form onSubmit={handleSubmit(onSubmit)}>
              <label className="custom-field  one fm_flddiv">
                <input type="text" id='_fname' placeholder=" " className="subinputfield"
                 {...register('_fname', { required: true })}/>
                <span className="placeholder">Name{errors._fname &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._fname && <p className='mt-1 important'>Name is a mandatory field</p>}
              </label>
               <label className="custom-field  one fm_flddiv">
                <input type="text" id='_phone' placeholder=" " className="subinputfield"
                {...register("_phone", {
                  required: true,
                    pattern:{
                      value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                    },
                })}/>
                <span className="placeholder">Contact Number{errors._phone &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._phone && <p className='mt-1 important'>Please enter a contact number</p>}
              </label>
              <label className="custom-field  one fm_flddiv">
                <input type="text" id='_email' placeholder=" " className="subinputfield"
                {...register("_email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                })}/>
                <span className="placeholder">Email{errors._email &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._email && <p className='mt-1 important'>Please enter a valid email address</p>}
              </label>
             
							<p className='field_p'>
              <ReadMoreReact min={70}
                ideal={90}
                max={200}
                readMoreText="Read more" text={'By signing up to the Evig Electronics you accept that Evig Electronics can contact...........'} /> 
							</p>
							<button type="submit">Subscribe Now</button>
              </form>
						</div>
        </div>
      </div>
    </div>
    </div>
   
    {/* <Modal show={show} onHide={handleClose} className='mysubset' backdrop={{ backgroundColor: "red" }}>
        <Modal.Header closeButton>
          <Modal.Title>SIGN UP & GET 10% OFF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="wrapper">
            <div className="popup-box">
            <p className='pop-p'>Subscribe to our newsletters now and stay up-to-date with new collections.</p>
            <div className="form-group mt-3">
            <form method="post" style={{textAlign:'center'}}>
                <input type="email" id='subinput' name="useremail-id" style={{textTransform:'lowercase'}} required placeholder="Please enter your email"/>
                <button type="submit" id="subscribe">SUBSCRIBE</button>
            </form>
            </div>
            </div>
        </div>
        </Modal.Body>
        
      </Modal> */}
    </>
  )
}

export default Subscribe