import React, { useRef, useEffect, useState,useLayoutEffect } from 'react';
import '../../Assets/css/style.css';
import '../../Assets/css/responsive.css';
import list1 from '../../Assets/images/list1.png';
import list2 from '../../Assets/images/list2.png';
import list3 from '../../Assets/images/list3.png';
import list4 from '../../Assets/images/list4.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import AOS from 'aos';
import 'aos/dist/aos.css';
import toast, { Toaster } from 'react-hot-toast';
import { useForm, Controller } from "react-hook-form";
import ReactReadMoreReadLess from "react-read-more-read-less";
import '../../Assets/css/style.css';
import '../../Assets/css/responsive.css';
import imgicon from '../../Assets/images/iconSearch.png';
import logo from '../../Assets/images/logo.png';
import hd1 from '../../Assets/images/hd1.png';
import hd2 from '../../Assets/images/hd2.png';
import hd3 from '../../Assets/images/hd3.png';
import hd4 from '../../Assets/images/hd4.png';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import listbanner from '../../Assets/images/listbanner.png';

// Import the Owl Carousel library
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import 'owl.carousel';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Navbar from '../Com/Navbar';
import pdf from '../../Assets/images/pdf.png';
import getin from '../../Assets/images/getin.png';
import getgray from '../../Assets/images/get.png';
import getclr from '../../Assets/images/getin.png';

gsap.registerPlugin(ScrollTrigger);
function Pagecontent() {
  const pdfLink = useRef(null);

	const handleDownload = () => {
	  pdfLink.current.click();
	};
  const targetRef = useRef(null);
		const [isFilled, setIsFilled] = useState(false);
		function handleIntersection(entries) {
			entries.forEach(entry => {
			  if (entry.isIntersecting) {
				setTimeout(() => {
					setIsFilled(true);
				  }, 1000);
			  } else {
				setIsFilled(false);
			  }
			});
		  }
    const buttonRef = useRef(null);
    useEffect(() => {
        const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log('button opened');
        } else {
            mousemovefn()
            console.log('button closed');
        }
      });
    }, options);

    const button = buttonRef.current;

    if (button) {
      observer.observe(button);
    }

    return () => {
      if (button) {
        observer.unobserve(button);
      }
    };
  }, []);
    useEffect(() => {
      function handleEscapeKey(event) {
        if (event.keyCode === 27) {
          setVisible(false);
        }
      }
  
      document.addEventListener('keydown', handleEscapeKey);
  
      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }, []);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
  
    useEffect(() => {
      // perform a search using the searchTerm
      // and update the searchResults state
      // You can implement this using an API call or any other search mechanism
      // Here is an example of hardcoding some search results
      const results = [
        "Evoko",
        "Origin Aquatic",
        "Aurora",
        "Avocar",
        "Draper",
        "Lutroin",
        "Playpp",
        "Sms",
        "Urc"
      ];
      const filteredResults = results.filter((result) =>
        result.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }, [searchTerm]);
  
    function handleInputChange(event) {
      setSearchTerm(event.target.value);
    }
    const [is_Open, setIs_open] = useState(false);
    const [Open, setOpen] = useState(false);
    const [Openp, setOpenp] = useState(false);
    const [visible, setVisible] = useState(false);
    // const handleButtonClick = (e) => {
    //   $(e.target).closest(".header-flex").toggleClass("show_mob_menu");
    // };
    const ToggleSidebar = () => {
        is_Open === true ? setIs_open(true) : setIs_open(true);
    }
    const ToggleSidebarclose = () => {
        is_Open === true ? setIs_open(false) : setIs_open(false);
    }
    const enablefun=()=>{
        Openp=== true?setOpenp(false):setOpenp(false);
        Open=== false?setOpen(true):setOpen(true);
        visible===false?setVisible(false):setVisible(false);
    }
    const enablep=()=>{
        Open=== true?setOpen(false):setOpen(false);
        Openp=== false?setOpenp(true):setOpenp(true);
        visible===false?setVisible(false):setVisible(false);
    }
    function handleclose(){
        Openp=== true?setOpenp(false):setOpenp(false);
        Open=== true?setOpen(false):setOpen(false);
        visible===false?setVisible(false):setVisible(false);
    }
    function opensearch(){
        Openp=== true?setOpenp(false):setOpenp(false);
        Open=== true?setOpen(false):setOpen(false);
        visible===false?setVisible(true):setVisible(false);
    }
	const [show, setShow] = useState(false);
	const [verfied, setVerifed] = useState(false);
	const [dis, setDis] = useState(false);
    const showmore=()=>{
        setDis(true)
    }
    const showless=()=>{
        setDis(false)
    }
	function onChange(value) {
		console.log("Captcha value:", value);
		setVerifed(true);
	  }
	
	  const handleClose = () => setShow(false);
	  const handleShow = () => setShow(true);
	  const myRef = useRef(null);
	  const {
		register,
		formState: { errors, isValid, isDirty },
		handleSubmit,
		getValues,
		control,
		setValue,
		setFocus,
		reset,
	
		setError,
		clearErrors,
		values,
		trigger,
	} = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
	});
		const onclick=()=>{
			if(verfied){
				toast.success('Submited Succesfully')
			}else{
			  toast.error('Capcha Validation failed')
			}
		  }
    const mousemovefn=()=>{
        ToggleSidebarclose()
        handleclose()
    }
    useEffect(()=>{
        var elem = document.getElementById("scrollid").offsetHeight;
        console.log(elem);
        
        ScrollTrigger.create({
          trigger: ".mail_s",
          start: 'top bottom',
          end: 'bottom top',
          
          // toggleClass: 'cool-effect'
          toggleClass: { targets: '#gt', className: 'cool-effect' },
      })
        // var splash = document.getElementById("gt");
        // setTimeout(function(){
        //   splash.classList.add("cool-effect");
        // }, 1500);
        
    },[])
    const main = useRef();
    useLayoutEffect(() => {
        const textOne = document.getElementById("sec1");

        const tweenOne = gsap.to(textOne, {
        y: -2500,
        
        scrollTrigger: {
            trigger: "#scrollid",
            pin: ".single_box",
            scrub: true,
            start: "top top",
            end: "bottom bottom",
            markers: false,
            pinSpacing: false,
        }

          
        }, main);
         // <- Scope!
        return () => tweenOne.revert(); // <- Cleanup!
      }, []);
      const [positiveNumber, setPositiveNumber] = useState('');    
      function handlePositiveNumberChange(event) {
        const inputValue = event.target.value;
        if (Number(inputValue)>0) {
          setPositiveNumber(inputValue);
        } else {
          setPositiveNumber('');
        }
      } 
      
  return (
    <>
	<Navbar/>
	<div>
	<div className="banner_innerpage" id="banner_innerpage"    onClick={mousemovefn} onMouseMove={mousemovefn} >
			<img src={listbanner} />			
		</div>
	</div>
    <div className="page_content" id="scrollid" >
			<div className="wrapper">
				<div className="flex_box_content_">
					<div className="single_box" id="cf" ref={main}> 
						<div className="image_b_block" id="sec1">
							<img src={list1} alt=''/>
                            <img src={list2} alt=''/>
                            <img src={list3} alt=''/>
                            <img src={list4} alt=''/>

						</div>
						<div className="content_b" id="sec2" >
							<div  >
								<h3>VLX-TC1-CF</h3>
								<span>4K IP Audio/Video <br/>Distribution Transceiver </span>
								<div className='hover_div' >
									<p>The VLX-TW2 Series provides one of the most advanced IP Streaming solutions on the market utilizing Aurora’s
                                         IPBaseTTM technology, which synergizes various IP/AV standards to work together as one. It is the industry’s
                                          first 4K UHD transceiver with only 1.5 Frame (25ms) latency and visually losses compression. Using a 
                                          transmitter (encoder) and receiver (decoder), respectively, used to be the standard – until now. 
                                          The VLX-TCW2 Series can be set up as either one to make installation, inventory, and troubleshooting easier.<br/>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p><br/>
                                    <span className='hoverhand rivread' onClick={showmore} style={{display:dis?'none':'block'}}>Read more</span></p>
									<p style={{display:dis?'block':'none'}}>The VLX-TW2 Series provides one of the most advanced IP Streaming 
                                    solutions on the market utilizing Aurora’s IPBaseTTM technology, which synergizes various IP/AV
                                    standards to work together as one. It is the industry’s first 4K UHD transceiver with only 1.5
                                    Frame (25ms) latency and visually losses compression. Using a transmitter (encoder) and receiver
                                    (decoder), respectively, used to be the standard – until now. The VLX-TCW2 Series can be set up
                                    as either one to make installation, inventory, and troubleshooting easier. Another industry first
                                     is the option slot to add other IP capabilities, like Dante® audio, for a more complete, 
                                    distributed system. The VLX-TW2 Series provides one of the most advanced IP Streaming solutions
                                     on the market utilizing Aurora’s IPBaseTTM technology, which synergizes various IP/AV standards
                                      to work together as one. It is the industry’s first 4K UHD transceiver with only 1.5 Frame
                                       (25ms) latency and visually losses compression. Using a transmitter (encoder) and receiver 
                                       (decoder), respectively, used to be the standard – until now. The VLX-TCW2 Series can be set
                                        up as either one to make installation, inventory, and troubleshooting easier. Another industry
                                         first is the option slot to add other IP capabilities, like Dante® audio, for a more complete,
                                          distributed system. <span className='hoverhand rivread' onClick={showless}>Read less</span></p>
                                    
								</div>
								
							</div>

							<div className="how_order">
								<h4 id='how_orderh3'>How to order us</h4>
								<p>Well! That's easy just contact us<br/> by clicking the button below</p>
								<button onClick={handleShow}>Contact Us</button>
							</div>
						</div>

					</div>

					
				</div>
			</div>
		</div>
		{/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          CONTACT US
        </Modal.Header>
        <Modal.Body>
        <div className="wrapper">
            <div className="popup-box">
            <div className="form-group mt-3">
            <form method="post" onSubmit={handleSubmit(onclick)} style={{textAlign:'center'}}>
              <Row>
                <Col>
                  <input type="text" id='subinput-list' name="firstname" required placeholder="First name"/>
                </Col>
                <Col>
                  <input type="text" id='subinput-list' name="lastname" required placeholder="Last name"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="email" id='subinput-list' name="useremail-id" required placeholder="Email"/>
                </Col>
              </Row>
			  <Row className='mt-2'>
                <Col>
                  <input type="text" id='subinput-list' name="useremail-id" required placeholder="Mobile"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                <textarea id='subinput-list' name="Message" rows="4" cols="50" placeholder="Message" required/>
                </Col>
              </Row>
                
              <ReCAPTCHA
          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          onChange={onChange}
        />
                <button type="submit"style={{backgroundColor:'black'}} id="subscribe">BOOK NOW</button>
            </form>
            </div>
            </div>
        </div>
        </Modal.Body>
        
      </Modal> */}
	  <Modal show={show} onHide={handleClose} className='bookdem'>
	  <Modal.Header >
			  <div  className='hoverhand' style={{width:'100%',textAlign:'right',color:'white'}}>
			  <h1   onClick={handleClose}>X</h1></div>
        </Modal.Header>
        <Modal.Body>
        <div className="wrapper">
            <div className="popup-box">
            <div className="form-group mt-3">
            <form method="post" onSubmit={handleSubmit(onclick)} style={{textAlign:'center'}}>
              
              <Row>
                <Col>
                  <input type="text" className='subinput' id='subinput' name="firstname" required placeholder="First name"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="text" className='subinput' id='subinput' name="lastname" required placeholder="Last name"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="email" className='subinput' id='subinput' name="useremail-id" required placeholder="Email"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="text" className='subinput' id='subinput' value={positiveNumber}  onChange={handlePositiveNumberChange} name="mobile" required placeholder="Mobile"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                <textarea id='subinputarea' className='subinput' name="Message" rows="4" cols="50" placeholder="Message" required/>
                </Col>
              </Row>
                
              <ReCAPTCHA
          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          onChange={onChange}
        />
                <button type="submit"style={{backgroundColor:'black'}} id="subscribe">BOOK NOW</button>
            </form>
            </div>
            </div>
        </div>
        </Modal.Body>
        
      </Modal>
      {/* .......................key feature..................... */}
      <div>
      <div className= {dis?'block_key specs listing_sp':'none_key specs listing_sp'}>
				
				<div className="liso">
					

					<div className="icon_boxs">
						
						<div className="icon_box">
							<h3>KEY FEATURES</h3>
							<ul>
									<li>Configure as Transmitter (Encoder) or Receiver (Decoder) </li>
									<li>4K UHD over 1G CAT 5e/6/6a </li>
									<li>HDMI 2.0, HDCP 2.2 </li>
									<li>Visually Lossless with only 1.5 Frame Latency </li>
									<li>128 per Source with HDCP, Larger for Non-Encrypted Sources </li>
									<li>Video Wall with Image Rotation </li>
									<li>1G LAN PoE </li>
									<li>1 SFP for Single/Multimode Fiber or Second RJ-45 </li>
									<li>2 HDMI Inputs, 1 HDMI Output </li>
									<li>Line In/Out Stereo </li>
									<li>RS-232 Serial Port and IR (In/Out) </li>
									<li>On Screen Display (OSD) </li>
									<li>Integrated Web Server for Configuration </li>
									<li>USB 2.0 Host/Device Port </li>
									<li>2 USB 1.1 for HID Devices </li>
									<li>Dante® IP Option </li>
									<li>Front Keypad and IR Remote </li>
									<li>Rack and Under Table Mounting </li>
							</ul>
						</div>

						<div className="mob_footer show_in_web_">
							<h3>GUIDE FILE(S)</h3>

							<div className="pdf">
								<img src={pdf} alt='' onClick={handleDownload}/>
								<a
        href="/path/to/pdf-file.pdf"
        download
        ref={pdfLink}
        style={{ display: 'none' }}
      ></a>
								<span>Datasheet <br/> 103 kb</span>
							</div>

						</div>






					</div>

				</div>

			</div>
				{/* <div className="mob_footer email_sec show_in_web_" ref={targetRef}> */}
				<div className='infomail' ref={targetRef}>
					<div className='mail_s' style={{textAlign:'center',width:'100%'}}>
          <div className="im_w">
              <img src={getgray} alt='' />
            
              <div className='get_im' id="gt">
                <img src={getclr} alt='' />
              </div>
              </div>
						<h2 id='hoveremail' >info@eviggroup.com</h2>
            
					</div>
				</div>
      </div>
	  <Toaster />
    </>
  )
}

export default Pagecontent