import React, { useRef, useEffect, useState } from 'react';
import Banner from '../Components/Home/Banner';
import Navbar from '../Components/Com/Navbar';
import Content from '../Components/Home/Content';
import Video1 from '../Components/Home/Video1';
import Video2 from '../Components/Home/Video2';
import Content2 from '../Components/Home/Content2';
import Content3 from '../Components/Home/Content3';
import Video3 from '../Components/Home/Video3';
import Userinterface from '../Components/Home/Userinterface';
import FindRoom from '../Components/Home/FindRoom';
import Specs from '../Components/Home/Specs';
import Footer from '../Components/Com/Footer';
import Subscribe from '../Components/Home/Subscribe';
import Mobcontent from '../Components/Home/Mobcontent';
import Mobcontent3 from '../Components/Home/Mobcontent3';

function Home() {
  const targetRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    if (targetRef.current) observer.observe(targetRef.current);
    return () => observer.disconnect();
  }, []);
  function handleIntersection(entries) {
		entries.forEach(entry => {
		  if (entry.isIntersecting) {
			  console.log('its okey');
		  }else{
		}
		});
	  }
  return (
    <div>
        <Banner/>
        <div className='d-none d-lg-block'>
          <Content/>
        </div>
        <div className="d-lg-none">
          <Mobcontent/>
        </div>
        <Video2/>
        <div className="empty_space_350 d-none d-md-block"></div>
        <Content2/>
        <div className='d-none d-lg-block'>
          <Content3/>
        </div>
        <div className="d-lg-none">
          <Mobcontent3/>
        </div>
        <Video3/>
        <Userinterface/>
        <FindRoom/>
        <Specs/>
        <Subscribe/>
        <Footer/>
    </div>
  )
}

export default Home