import React, { useRef, useEffect, useState,useLayoutEffect } from 'react';
import bgbanner from '../../Assets/images/roomMngr.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
function Content3() {
  const main2 = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.to('#move_3', {
        xPercent: 20,
        ease: "none",
        scrollTrigger: {
          trigger: "#move_3",
          start: "top bottom",
          end: "bottom top",
          scrub: true
        }
      })

      gsap.to('#move_5', {
        xPercent: -20,
        ease: "none",
        scrollTrigger: {
          trigger: "#move_5",
          start: "top bottom",
          end: "bottom top",
          scrub: true
        }
      })

      
    }, main2);
     // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);  
  return (
    <div className="content_bitween pos_sec room_status">
    <div className="content_1" id="contaniner_2" ref={main2}>
        <h2  className="container evokoliso" id="move_3">See room <br/> status at a glance </h2>
        <p  className="container  contntevoko" id="move_5">
            A self hosted room manager that brings all the <br/>
            features you need in a room booking device. <br/>
            Place it outside any meeting, conference or huddle room. 
        </p>
    </div>
    
</div>
  )
}

export default Content3