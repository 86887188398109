import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Suspense } from "react";
import Home from "./Pages/Home";
import Listing from "./Pages/Listing";

function App() {
  return (
    <div className="App" style={{overflowX:'hidden'}}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Listing" element={<Listing />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
