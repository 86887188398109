import React, { useRef, useEffect, useState } from 'react';
import vid from '../../Assets/videos/v3.mp4';

function Video3() {
  const videoRef3 = useRef(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.play();
        } else {
          entry.target.pause();
        }
      });
    }, options);
    const video = videoRef3.current;
    if (video) {
      observer.observe(video);
    }
    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }, []);
  const handleVideoError = () => {
    console.error('Video cannot be played');
  };
  return (
    <>
    <div style={{ width: '100%'}}>
    <div className="video video1 pos_sec height-sec">  
        <video
          loop autoplay="autoplay" playsInline muted ref={videoRef3}
          onError={handleVideoError}
        >
        <source src={vid} type="video/mp4" />
        Your browser does not support HTML5 video.
        </video>
      </div>
      </div>
      {/* <div style={{ width: '100%' }} className='d-md-none'>
      <div style={{ position: "relative" }}>  
        <video
          ref={videoRef}
          width="100%"
          height="360"
          autoPlay
          muted
          loop
          onCanPlay={() => videoRef.current.play()}
          onError={handleVideoError}
        >
        <source src={vid} type="video/mp4" />
        Your browser does not support HTML5 video.
        </video>
      </div>
      </div> */}
      </>
  )
}

export default Video3