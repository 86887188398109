import React, { useRef, useEffect, useState } from 'react';
import chart from '../../Assets/images/chart.png';
import calender from '../../Assets/images/calender.png';
import sensor from '../../Assets/videos/sensor.mp4';
import lang from '../../Assets/videos/language.mp4';
import controls from '../../Assets/videos/controll.mp4';
import click from '../../Assets/videos/click.mp4';
import meeting from '../../Assets/images/meeting.png';
import chartmb from '../../Assets/images/chartmb.png';
import '../../Assets/css/style.css';
import '../../Assets/css/responsive.css';
import $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import toast, { Toaster } from 'react-hot-toast';
import { useForm, Controller } from "react-hook-form";
import rply from '../../Assets/images/reply.png';


function Userinterface() {
    const handleVideoError = () => {
      console.error('Video cannot be played');
    };
    useEffect(() => {
		AOS.init({
			
		});

		AOS.refresh();
	}, []);


	const [show, setShow] = useState(false);
  	const [verfied, setVerifed] = useState(false);
  	const {
    formState: { errors, isValid, isDirty },
    handleSubmit,
	} = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
	});
  //recaptcha function
	function onChange(value) {
		console.log("Captcha value:", value);
		setVerifed(true);
	}
  	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);    
	const onclick=()=>{
	if(verfied){
		toast.success('Submited Succesfully')
	}else{
		toast.error('Capcha Validation failed')
	}
	}
	// .....................video player on scroll...........................
	const videoRef1 = useRef(null);
	let functionCallCount1=0;
  	useEffect(() => {
		const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.5,
		};
		const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
			entry.target.play();
			console.log('v1 play');
			} else {
			entry.target.pause();
			console.log('v1 pouse');
			}
		});
		}, options);
		const video = videoRef1.current;
		if (video) {
		observer.observe(video);
		}
		return () => {
		if (video) {
			observer.unobserve(video);
		}
		};
  	}, []);
	  const handleTimeUpdate1 = () => {
		functionCallCount1=functionCallCount1+1;
		if(functionCallCount1<=100){
			var video = document.getElementById("video222");
			video.play();
			var img=document.getElementById('rply2');
			img.style.display='none';
		}else{
			console.log('restarted');
			var video = document.getElementById("video222");
			video.pause();
			var img=document.getElementById('rply2');
			img.style.display='block';
		}
	};
	function restartFunctionCallCount1() {
		functionCallCount1 = 0;
		playvideo2();
	  }
	const playvideo2=()=>{
		var video = document.getElementById("video222");
		
		video.play();
	}
	const videoRef2 = useRef(null);
	let functionCallCount=0;
  	useEffect(() => {
		const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.5,
		};
		const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.play();
			} else {
				entry.target.pause();
				console.log('v2 pouse');
			}
		});
		}, options);
		const video = videoRef2.current;
		if (video) {
		observer.observe(video);
		}
		return () => {
		if (video) {
			observer.unobserve(video);
		}
		};
  	}, []);
	const handleTimeUpdate = () => {
		functionCallCount=functionCallCount+1;
		if(functionCallCount<=100){
			var video = document.getElementById("video111");
			video.play();
			var img=document.getElementById('rply1');
			img.style.display='none';
		}else{
			var video = document.getElementById("video111");
			video.pause();
			var img=document.getElementById('rply1');
			img.style.display='block';
		}
	};
	function restartFunctionCallCount() {
		functionCallCount = 0;
		playvideo1();
	  }
	const playvideo1=()=>{
		var video = document.getElementById("video111");
		video.play();
	}
	const videoRef3 = useRef(null);
	let functionCallCount3 = 0;
  	useEffect(() => {
		const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.5,
		};
		const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
			entry.target.play();
			console.log('v3 play');
			} else {
			entry.target.pause();
			console.log('v3 pouse');
			}
		});
		}, options);
		const video = videoRef3.current;
		if (video) {
		observer.observe(video);
		}
		return () => {
		if (video) {
			observer.unobserve(video);
		}
		};
  	}, []);
	const handleTimeUpdate3 = () => {
		functionCallCount3=functionCallCount3+1;
		if(functionCallCount3<=100){
			var video = document.getElementById("video333");
			video.play();
			var img=document.getElementById('rply3');
			img.style.display='none';
		}else{
			var video = document.getElementById("video333");
			video.pause();
			var img=document.getElementById('rply3');
			img.style.display='block';
		}
	};
	function restartFunctionCallCount3() {
		functionCallCount3 = 0;
		playvideo3();
	  }
	const playvideo3=()=>{
		var video = document.getElementById("video333");
		video.play();
	}
	const videoRef4 = useRef(null);
	let functionCallCount4 = 0;
  	useEffect(() => {
		const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.5,
		};
		const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
			entry.target.play();
			console.log('v4 play');
			} else {
			entry.target.pause();
			console.log('v4 pouse');
			}
		});
		}, options);
		const video = videoRef4.current;
		if (video) {
		observer.observe(video);
		}
		return () => {
		if (video) {
			observer.unobserve(video);
		}
		};
  	}, []);
	const handleTimeUpdate4 = () => {
		functionCallCount4=functionCallCount4+1;
		if(functionCallCount4<=100){
			var video = document.getElementById("video444");
			video.play();
			var img=document.getElementById('rply4');
			img.style.display='none';
		}else{
			var video = document.getElementById("video444");
			video.pause();
			var img=document.getElementById('rply4');
			img.style.display='block';
		}
	};
	function restartFunctionCallCount4() {
		functionCallCount4 = 0;
		playvideo4();
	  }
	const playvideo4=()=>{
		var video = document.getElementById("video444");
		video.play();
	}
	// ......................video on mobile......................
	const videoRefm1 = useRef(null);
	let functionCallCountm1=0;
	useEffect(() => {
	  const options = {
	  root: null,
	  rootMargin: '0px',
	  threshold: 0.5,
	  };
	  const observer = new IntersectionObserver((entries) => {
	  entries.forEach((entry) => {
		  if (entry.isIntersecting) {
		  entry.target.play();
		  console.log('v1 play');
		  } else {
		  entry.target.pause();
		  console.log('v1 pouse');
		  }
	  });
	  }, options);
	  const video = videoRefm1.current;
	  if (video) {
	  observer.observe(video);
	  }
	  return () => {
	  if (video) {
		  observer.unobserve(video);
	  }
	  };
	}, []);
	const handleTimeUpdatem1 = () => {
		functionCallCountm1=functionCallCountm1+1;
		if(functionCallCountm1<=100){
			var video = document.getElementById("videom1");
			video.play();
			var img=document.getElementById('rplym1');
			img.style.display='none';
		}else{
			var video = document.getElementById("videom1");
			video.pause();
			var img=document.getElementById('rplym1');
			img.style.display='block';
		}
	};
	function restartFunctionCallCountm1() {
		functionCallCountm1 = 0;
		playvideom1();
	  }
	const playvideom1=()=>{
		var video = document.getElementById("videom1");
		video.play();
	}
  	const videoRefm2 = useRef(null);
	let functionCallCountm2=0;
	useEffect(() => {
	  const options = {
	  root: null,
	  rootMargin: '0px',
	  threshold: 0.5,
	  };
	  const observer = new IntersectionObserver((entries) => {
	  entries.forEach((entry) => {
		  if (entry.isIntersecting) {
		  entry.target.play();
		  console.log('v1 play');
		  } else {
		  entry.target.pause();
		  console.log('v1 pouse');
		  }
	  });
	  }, options);
	  const video = videoRefm2.current;
	  if (video) {
	  observer.observe(video);
	  }
	  return () => {
	  if (video) {
		  observer.unobserve(video);
	  }
	  };
	}, []);
	const handleTimeUpdatem2 = () => {
		functionCallCountm2=functionCallCountm2+1;
		if(functionCallCountm2<=100){
			var video = document.getElementById("videom2");
			video.play();
			var img=document.getElementById('rplym3');
			img.style.display='none';
		}else{
			var video = document.getElementById("videom2");
			video.pause();
			var img=document.getElementById('rplym3');
			img.style.display='block';
		}
	};
	function restartFunctionCallCountm2() {
		functionCallCountm2 = 0;
		playvideom2();
	  }
	const playvideom2=()=>{
		var video = document.getElementById("videom2");
		video.play();
	}
  const videoRefm3 = useRef(null);
  let functionCallCountm3=0;
	useEffect(() => {
	  const options = {
	  root: null,
	  rootMargin: '0px',
	  threshold: 0.5,
	  };
	  const observer = new IntersectionObserver((entries) => {
	  entries.forEach((entry) => {
		  if (entry.isIntersecting) {
		  entry.target.play();
		  console.log('v1 play');
		  } else {
		  entry.target.pause();
		  console.log('v1 pouse');
		  }
	  });
	  }, options);
	  const video = videoRefm3.current;
	  if (video) {
	  observer.observe(video);
	  }
	  return () => {
	  if (video) {
		  observer.unobserve(video);
	  }
	  };
	}, []);
	const handleTimeUpdatem3 = () => {
		functionCallCountm3=functionCallCountm3+1;
		if(functionCallCountm3<=100){
			var video = document.getElementById("videom3");
			video.play();
			var img=document.getElementById('rplym4');
			img.style.display='none';
		}else{
			var video = document.getElementById("videom3");
			video.pause();
			var img=document.getElementById('rplym4');
			img.style.display='block';
		}
	};
	function restartFunctionCallCountm3() {
		functionCallCountm3 = 0;
		playvideom3();
	  }
	const playvideom3=()=>{
		var video = document.getElementById("videom3");
		video.play();
	}
  const videoRefm4 = useRef(null);
  let functionCallCountm4=0;
	useEffect(() => {
	  const options = {
	  root: null,
	  rootMargin: '0px',
	  threshold: 0.5,
	  };
	  const observer = new IntersectionObserver((entries) => {
	  entries.forEach((entry) => {
		  if (entry.isIntersecting) {
		  entry.target.play();
		  console.log('v1 play');
		  } else {
		  entry.target.pause();
		  console.log('v1 pouse');
		  }
	  });
	  }, options);
	  const video = videoRefm4.current;
	  if (video) {
	  observer.observe(video);
	  }
	  return () => {
	  if (video) {
		  observer.unobserve(video);
	  }
	  };
	}, []);
	const handleTimeUpdatem4 = () => {
		functionCallCountm4=functionCallCountm4+1;
		if(functionCallCountm4<=100){
			var video = document.getElementById("videom4");
			video.play();
			var img=document.getElementById('rplym2');
			img.style.display='none';
		}else{
			var video = document.getElementById("videom4");
			video.pause();
			var img=document.getElementById('rplym2');
			img.style.display='block';
		}
	};
	function restartFunctionCallCountm4() {
		functionCallCountm4 = 0;
		playvideom4();
	  }
	const playvideom4=()=>{
		var video = document.getElementById("videom4");
		video.play();
	}
	
  return (
	<>
<div className="user_interface web_user">
	<div className="content_wrapper">
		<h2>Intuitive user interface </h2>
		<p>Intuitive and user friendly interface </p>

		<div className="boxs" >
			<div className="top_boxs">
				<div className="wd_30" >
					<div>
						<h4 className='fontstyle_h5'>
							Control through<br/>  Your Phone.
						</h4>
						<p className='fontstyle_p'>
							Intuitive and user<br/> friendly interface 
						</p>
						<img src={rply} id='rply1' className='replay_img' alt='' onClick={restartFunctionCallCount} />
					</div>
					<div class="video_change">
					<video 
						id='video111'
						width="320"
						height="240"
						playsinline
						muted
						onTimeUpdate={handleTimeUpdate}
						ref={videoRef2}
						loop							
						onError={handleVideoError}>
						<source src={controls} type="video/mp4"/>
						Your browser does not support the video tag.
					</video>
					</div>					
				</div>

				<div className="wd_70">
					<div className="box_50 touch" >
						
						<h5 className='fontstyle_h5'><button className='bookbutn' onClick={handleShow}>Book</button>&nbsp;
							with the touch <br/>of your fingertip. 
						</h5>
						<img src={rply} id='rply2' className='replay_img' alt='' onClick={restartFunctionCallCount1} />
						<video 
						id='video222'
						ref={videoRef1}
						onTimeUpdate={handleTimeUpdate1}
						width="320" 
						height="240"
						playsinline 
						muted
						loop 
						onError={handleVideoError}>
							<source src={click} type="video/mp4"/>
							Your browser does not support the video tag.
						</video>
					</div>
					<div className="box_50 meeting">
						<img src={meeting} alt=''/>
						<h5 className='fontstyle_h5'>
							See meeting subject <br/>
							and the organizer
						</h5>
					</div>
					<div className="box_50 language" >
						<h5 className='fontstyle_h5'>User interface languages</h5>
						<img className='replay_img' id='rply3' src={rply} alt='' onClick={restartFunctionCallCount3} />
						<video
							id='video333'
							width="320"
							height="240"
							playsinline
							muted
							onTimeUpdate={handleTimeUpdate3}
							ref={videoRef3} 
							loop 
							onError={handleVideoError}>
							<source src={lang} type="video/mp4"/>
							Your browser does not support the video tag.
						</video>

					</div>
					<div className="box_50 sensor" >
						<h5 className='fontstyle_h5'>Built in proximity sensor <span  className='fontstyle_span'>as you approach the screen</span></h5>
						<img src={rply} id='rply4' className='replay_img' alt='' onClick={restartFunctionCallCount4} />
						<video
							id='video444'
							width="300" 
							height="200"
							playsinline
							muted
							onTimeUpdate={handleTimeUpdate4}
							ref={videoRef4}
							loop  
							onError={handleVideoError}>
							<source src={sensor} type="video/mp4"/>
							Your browser does not support the video tag.
						</video>
					</div>

				</div>

			</div>

			<div className="top_boxs">
				<div className="wd_30 sc-ch">
						<h5>
							Digital Calender
						</h5>
					<img src={calender} alt=''/>
				</div>

				<div className="wd_70 sc-ch">
					
						<h5>
							Meeting room statistics
						</h5>
					
					<img src={chart} alt=''/>
				</div>
			</div>
		</div>
	</div>
</div>

<div className="mobile_user mobile_user user_interface">
	<div className="content_wrapper"  style={{paddingLeft:10}}>
		<h2>Intuitive user interface </h2>
		<p>Intuitive and user friendly interface </p>
		   {/* <div className='owl_int_mb' > */}
		   <OwlCarousel className='owl-carousel owl-theme' loop margin={40} dots items={1} >
		   <div className='item mb_item' >
				<div className="wd_30">
					<div>
						<h4>
							Control through <br/> Your Phone.
						</h4>
						<p>
							Intuitive and user friendly interface 
						</p>
						<img className='replay_img' id='rplym1' src={rply} alt='' onClick={restartFunctionCallCountm1} />
					</div>	
					<div class="video_change">		
					<video 
						id='videom1'
						width="320"
						height="240"
						loop 
						autoplay="autoplay"
						playsInline
						muted
						onTimeUpdate={handleTimeUpdatem1}
						ref={videoRefm1}
						onError={handleVideoError}>
						<source src={controls} type="video/mp4" />
						Your browser does not support HTML5 video.
					</video>
					</div>	
				</div>
			</div>
			<div className="item mb_item" >
				<div className="box_50 touch">
					<h5>
					<span className="book-button" onClick={handleShow}>Book </span>with the touch <br/> of your fingertip. 
					</h5>
					<img className='replay_img' id='rplym2' src={rply} alt='' onClick={restartFunctionCallCountm4} />
					<video
						id='videom4'
						width="320"
						height="240"
						loop
						autoplay="autoplay" 
						playsInline 
						muted 
						onTimeUpdate={handleTimeUpdatem4}
						ref={videoRefm2}
						onError={handleVideoError}>
						<source src={click} type="video/mp4" />
						Your browser does not support HTML5 video.
					</video>
				</div>
				<div className="box_50 language">
					<h5>User interface languages</h5>
					<img className='replay_img' id='rplym3' src={rply} alt='' onClick={restartFunctionCallCountm2} />
					<video 
						id='videom2'
						width="320"
						height="240"
						loop
						autoplay="autoplay" 
						playsInline 
						muted
						onTimeUpdate={handleTimeUpdatem2} 
						ref={videoRefm3}
						onError={handleVideoError}>
						<source src={lang} type="video/mp4" />
						Your browser does not support HTML5 video.
					</video>

				</div>
					
			</div>
			<div className="item mb_item">
				<div className="box_50 meeting">
					<img src={meeting} alt=''/>
					<h5>
						See meeting subject <br/>
						and the organizer
						
					</h5>
				</div>						
				<div className="box_50 sensor">
					<h5>Built in proximity sensor <span>as you approach the screen</span></h5>
					<img src={rply} id='rplym4' className='replay_img' alt='' onClick={restartFunctionCallCountm3} />
					<video 
						id='videom3'
						width="320"
						height="240"
						loop 
						autoplay="autoplay" 
						playsInline 
						muted 
						onTimeUpdate={handleTimeUpdatem3}
						ref={videoRefm4}
						onError={handleVideoError}>
						<source src={sensor} type="video/mp4"/>
						Your browser does not support the video tag.
					</video>
				</div>
			</div>
			<div className="item mb_item">
				<div className="wd_70 sc-ch">						
					<img src={chartmb} alt=''/>
				</div>
				
			</div>
			<div className="item">
				<div className="wd_30 sc-ch">
					<h5>
						Digital Calender
					</h5>
					<img src={calender} alt=''/>
				</div>
			</div>	
			</OwlCarousel>			
		{/* </div>			 */}
	</div>
</div>

<Modal show={show} onHide={handleClose} className='bookdem'>
        <Modal.Header >
			<div className='hoverhand' style={{width:'100%',textAlign:'right',color:'white'}}>
			<h1  onClick={handleClose}>X</h1></div>
        </Modal.Header>
        <Modal.Body>
        <div className="wrapper">
            <div className="popup-box">
            <div className="form-group mt-3">
            <form method="post" onSubmit={handleSubmit(onclick)} style={{textAlign:'center'}}>
              
              <Row>
                <Col>
                  <input type="text" className='subinput' id='subinput' name="firstname" required placeholder="First name"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="text" className='subinput' id='subinput' name="lastname" required placeholder="Last name"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="email" className='subinput' id='subinput' name="useremail-id" required placeholder="Email"/>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col>
                  <input type="text" className='subinput' id='subinput' name="mobile" required placeholder="Mobile"/>
                </Col>
              </Row>
              <Row className='mt-2 mb-2'>
                <Col>
                <textarea id='subinputarea' className='subinput' name="Message" rows="4" cols="50" placeholder="Message" required/>
                </Col>
              </Row>
                
              <ReCAPTCHA
          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          onChange={onChange}
        />
                <button type="submit"style={{backgroundColor:'black'}} id="subscribe">BOOK NOW</button>
            </form>
            </div>
            </div>
        </div>
        </Modal.Body>
      </Modal>
	  
</>
  )
}

export default Userinterface