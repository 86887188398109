import React from 'react';
import pdf from '../../Assets/images/pdf.png';
import specs from '../../Assets/images/specs.png';
import orangebg from '../../Assets/images/orangebg.png';
import pinkbg from '../../Assets/images/pinkbg.png';
import ic1 from '../../Assets/images/ic1.png';
import ic2 from '../../Assets/images/ic2.png';
import ic3 from '../../Assets/images/ic3.png';
import ic4 from '../../Assets/images/ic4.png';
import ic5 from '../../Assets/images/ic5.png';
import ic6 from '../../Assets/images/ic6.png';
import ic7 from '../../Assets/images/ic7.png';
import ic8 from '../../Assets/images/ic8.png';

function Specs() {
  return (
    <div className="specs">
        <div className="specs_box">
            <h2>Specs!</h2>
            <img src={specs} alt=''/>
        </div>
        <div className="liso">
            <img src={orangebg} alt='' className="orange"/>
            <img src={pinkbg} alt='' className="pink"/>
            <div className="liso_box1">
                <h3>Liso</h3>
                <p>Intuitive user interface</p>
                <span className="bordersss"></span>
            </div>

					<div className="icon_boxs">
						<div className="icon_box">
                        <img src={ic1} alt='' />
							<h3 className='head_home_h3'>Display</h3>
							<ul>
								<li className='head_home_li'>8 inch </li>
								<li className='head_home_li'>capacitive touch with </li>
								<li className='head_home_li'>anti fingerprint treatment</li>
							</ul>
						</div>

						<div className="icon_box">
							<img src={ic2} alt='' />
							<h3 className='head_home_h3'>Connectivity</h3>
							<ul>
								<li>Wi-Fi</li>
									<li className='head_home_li'>802.11 a/b/g/n</li>
									<li className='head_home_li'>Ethernet</li>
									 <li className='head_home_li'>RJ-45, 10/100/1000 Mbit</li>
									<li className='head_home_li'>PoE & PoE +</li>
									<li className='head_home_li'>RFID</li>
									<li className='head_home_li'>13.56 MHz reader</li>
									<li className='head_home_li'>ISO/IEC 14443A/B</li>
									<li className='head_home_li'>Supports MIFARE 4K/1K card (does not </li>
									<li className='head_home_li'>support ISO/IEC 15693 or MIFARE Ultralight C)</li>
									<li className='head_home_li'>-</li>
							</ul>
						</div>
						<div className="icon_box">
                        <img src={ic3} alt='' />
							<h3 className='head_home_h3'>Power</h3>
							<ul>
								
									<li className='head_home_li'>Power over Ethernet (PoE), or</li>
									<li className='head_home_li'>12V AC/DC power adapter (accessory)</li>
							</ul>
						</div>
						<div className="icon_box">
                        <img src={ic4} alt='' />
							<h3 className='head_home_h3'>Sensors</h3>
							<ul>
									<li className='head_home_li'>Proximity sensor </li>
									<li className='head_home_li'>Ambient light sensor</li>
							</ul>
						</div>

						<div className="icon_box">
                        <img src={ic5} alt='' />
							<h3 className='head_home_h3'>Mounting</h3>
							<ul>
									<li className='head_home_li'>5-way cable exit option for easy and neat installation</li>
										<li className='head_home_li'>Standard wall mount included in package</li>
										<li className='head_home_li'>Glass wall mount included in package</li>
							</ul>
						</div>

						<div className="icon_box">
                            <img src={ic6} alt='' />
							<h3 className='head_home_h3'>User interface languages</h3>
							<ul>
									<li className='head_home_li'> Arabic</li>
									<li className='head_home_li'>Catalan</li>
									<li className='head_home_li'>Chinese, Simplified</li>
									<li className='head_home_li'>Chinese, Traditional</li>
									<li className='head_home_li'>Czech</li>
									<li className='head_home_li'>Danish</li>
									<li className='head_home_li'>Dutch</li>
									<li className='head_home_li'>English</li>
									<li className='head_home_li'>Estonian</li>
									<li className='head_home_li'>Finnish</li>
									<li className='head_home_li'>French</li>
									<li className='head_home_li'>Galician</li>
									<li className='head_home_li'>German</li>
									<li className='head_home_li'>Icelandic</li>
									<li className='head_home_li'>Italian</li>
									<li className='head_home_li'>Japanese</li>
									<li className='head_home_li'>Korean</li>
									<li className='head_home_li'>Latvian</li>
									<li className='head_home_li'>Lithuanian</li>
									<li className='head_home_li'>Norwegian</li>
									<li className='head_home_li'>Polish</li>
									<li className='head_home_li'>Portuguese</li>
									<li className='head_home_li'>Russian</li>
									<li className='head_home_li'>Spanish</li>
									<li className='head_home_li'>Swedish</li>
									<li className='head_home_li'>Thai</li>
							</ul>
						</div>

						<div className="icon_box">
                        <img src={ic8} alt='' />
							<h3 className='head_home_h3'>Compatible platforms</h3>
							<ul>
									<li className='head_home_li'>Microsoft Office 365 </li>
										<li className='head_home_li'>Microsoft Exchange 2019</li>
										<li className='head_home_li'>Microsoft Exchange 2016</li>
										<li className='head_home_li'>Microsoft Exchange 2013</li>
										<li className='head_home_li'>Google G Suite</li>
										<li className='head_home_li'>IBM/Lotus Domino Smart cloud</li>
										<li className='head_home_li'>IBM/Lotus Domino 8.5.3 or later</li>
										<li className='head_home_li'>Evoko Booking (stand-alone version)</li>
							</ul>
						</div>
						<div className="icon_box">
                        <img src={ic7} alt='' />
							<h3 className='head_home_h3'>Measurements and weight</h3>
							<ul>
									<li className='head_home_li'>200 x 200 x 25 mm </li>
									<li className='head_home_li'>(7.9 x 7.9 x 1.0 inch)</li>
									<li className='head_home_li'>Weight: 1.3 kg (2.9 lbs)</li>
							</ul>
						</div>
						<div className="icon_box">
							<h3 className='head_home_h3'>Others</h3>
							<ul>
									<li className='head_home_li'>3-way indirect light aura to make room </li>
									<li className='head_home_li'>status visible already from a distance</li>
									<li className='head_home_li'>Open API for third party integration</li>
									<li className='head_home_li'>Communication via real-time two-way connection</li>
									<li className='head_home_li'>Remote management with multi-site support</li>
									<li className='head_home_li'>Real-time monitoring & statistics</li>
									<li className='head_home_li'>Recommended Specifications: Dual Core CPU-4GB-30GB</li>
							</ul>
						</div>

						<div className="mob_footer">
							<h3 className='head_home_h3'>GUIDE FILE(S)</h3>

							<div className="pdf">
								<img src={pdf} alt=''/>
								<span className='head_home_li'>D89 <br/> 411 kb</span>
							</div>

						</div>






					</div>

				</div>

			</div>
  )
}

export default Specs