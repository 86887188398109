import React, { useRef, useEffect, useState,useLayoutEffect } from 'react';
import '../../Assets/css/style.css';
import '../../Assets/css/responsive.css';
import bgbanner from '../../Assets/images/bg-banner.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
function Mobcontent() {
  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.to('#move_m', {
        xPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: "#move_m",
          start: "top bottom",
          end: "bottom top",
          scrub: true
        }
      })

      gsap.to('#move_m2', {
        xPercent: -15,
        ease: "none",
        scrollTrigger: {
          trigger: "#move_m2",
          start: "top bottom",
          end: "bottom top",
          scrub: true
        }
      })

      
    }, main);
     // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);  
  return (
    <div className="content_bitween pos_sec">
    <img src={bgbanner} className="bg-pos"/>
    <div className="content_1" id="container_m" ref={main}>
        <h2 className="container revealevokoliso" id="move_m">EVoko Liso</h2>
        <p className="container reveal contntevoko" id="move_m2">
            is the heart of our full-fledged <br/>self hosted room booking solution <br/>that brings all the features you need.
        </p>
    </div>    
    </div>
  )
}

export default Mobcontent