import React, { useRef, useEffect, useState } from 'react';
import room2 from '../../Assets/images/room2.png';
import room from '../../Assets/images/room.png';
import greenbg from '../../Assets/images/greenbg.png';
import ph1 from '../../Assets/images/ph1.png';
import ph2 from '../../Assets/images/ph2.png';
import ph3 from '../../Assets/images/ph3.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import v4 from '../../Assets/videos/v4.mp4'
import { TimelineLite } from 'gsap/gsap-core';
import { Container } from 'react-bootstrap';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import $ from 'jquery'

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);
function FindRoom() {
    const targetRef = useRef(null);
    const handleVideoError = () => {
        console.error('Video cannot be played');
      };
    
    const [img1, setImg1] = useState(1);
    const [img2, setImg2] = useState(2);
    const [img3, setImg3] = useState(3);
  

    
    const videoRef = useRef(null);

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };
  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.play();
          } else {
            entry.target.pause();
          }
        });
      }, options);
  
      const video = videoRef.current;
  
      if (video) {
        observer.observe(video);
      }
  
      return () => {
        if (video) {
          observer.unobserve(video);
        }
      };
    }, []);
      const targetRef5 = useRef(null);
      useEffect(()=>{
        const observer5 = new IntersectionObserver(handleIntersection5);
        if (targetRef5.current) observer5.observe(targetRef5.current);
        return () => observer5.disconnect();
      },[])
      function handleIntersection5(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            console.log('555');
            var video = document.getElementById("myVideo5");
            video.play();
          }else{
          console.log('--5');
          var video = document.getElementById("myVideo5");
          video.pause();
        }
        });
        }
        var w = window.innerWidth;
       
        useEffect(()=>{
          if(w > 768){
           

          var tl = new TimelineLite({paused:false});
          tl.to(".image_right", 0.6, {x:-41, y:-40}, 1);
          var tl1 = new TimelineLite({});
          tl.to(".image_left", 0.6, {x:25, y:45}, 1);
          var tl2 = new TimelineLite({});
                tl.to(".img_top", 0.6, {x:5, y:15}, 1);

          var hoverArea = $('.img_block');
          
          hoverArea.on("mouseenter", function(){
            tl.play();
            tl1.play();
            tl2.play();
          });
          
          hoverArea.on("mouseleave", function(){
            tl.reverse();
            tl1.reverse();
            tl2.reverse();
          });


          
      }   
        else {
          
          ScrollTrigger.create({
            trigger: '.img_block',
            start: "top bottom",
            end: "bottom top",
            // toggleActions: "play pause reverse pause",
            onEnter: () => tl.play(),
            onLeave: () => tl.reverse(),
            onEnterBack: () => tl.play(),
            onLeaveBack: () => tl.reverse()
        })
        var tl = new TimelineLite({paused:false});
        tl.to(".image_right", 0.6, {x:-5, y:10}, 1);
                          tl.to(".image_left", 0.6, {x:25, y:45}, 1);
                          tl.to(".img_top", 0.6, {x:20, y:40}, 1);
 
        
                        

        }
        },[])
        
  return (
    <>
<div class="find_room">
    <div class="room">
        <div className='content_wrapper'>
          <div class="room_title">
              <h2>Quickly find your room.</h2>
              <p>Bright and vivid colors indicates if a room is available or occupied </p>
          </div>
        </div>
        <div class="image_video">
            <img src={room2} alt=''/>
            <video width="320" height="240" loop id='myVideo5' autoplay="autoplay" playsInline muted ref={videoRef}
          onError={handleVideoError}>
              <source src={v4} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
        </div>
        <img src={room} alt=''/>
    </div>
</div>
    <div className="mobileapp" >
        <div className="app">
          <div className='content_wrapper'>
            <div className="room_title">
                <h2 className="container">EASE WITH MOBILE APP</h2>
                <p className="container">Bright and vivid colors indicates if a room is available or occupied </p>
              </div>  
          </div>
            <img src={greenbg} className="positionbg" alt=''/>
            <Container>
            <div className="img_block">

                
              
                <div className="top_image">
                    
                    <img src={ph3} className="img_top" id="i1"/>
                </div>
                
                <div className="block_2_position">
                    <div className="inner_block left_im">
                        <img  src={ph1} className="image_left" alt='' id="i2"/>
                    </div>
                    <div className="inner_block right_im">
                        <img  src={ph2}  className="image_right" alt='' id="i3"/>
                    </div>
                </div>


            </div>
            </Container>
        </div>
    </div>
    </>
  )
}

export default FindRoom