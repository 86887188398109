import React, { useRef, useEffect, useState } from 'react';
import '../Assets/css/style.css';
import '../Assets/css/responsive.css';
import Navbar from '../Components/Com/Navbar';
import Footer from '../Components/Com/Footer';
import listbanner from '../Assets/images/listbanner.png';
import Pagecontent from '../Components/Listing/Pagecontent';
import KeyFeatures from '../Components/Listing/KeyFeatures';
import Slider from '../Components/Listing/Slider';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container } from 'react-bootstrap';
import ReactReadMoreReadLess from "react-read-more-read-less";
import ReadMoreReact from 'read-more-react';
import Mobpagecontent from '../Components/Listing/Mobpagecontent';
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from 'react-hot-toast';

function Listing() {
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    setError,
    control,
    values,
    watch,

    reset,
    trigger,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  console.log("err", errors)
    const [show, setShow] = useState(false);

    const handleShow = () => {
      show?setShow(false):setShow(true);
    };
    const myRef = useRef(null);
    const onSubmit = data => {
      console.log(data);
    };
  return (
    <div className="white_bg_body">
      <div className='mob_cc'>
        <Pagecontent/>
      </div> 
      <div className='desc_cc'>
        <Mobpagecontent/>
        <KeyFeatures/>
      </div>        
        <Slider/>
        <div className="subscribe list_foot">
            <div className="subscripe_now" data-aos-anchor-placement="top-center">
                <h2>Be inspired, sign up now</h2>
                <p >Never miss the latest offers from Evig, tips and demos,<br/> news and invites to local events.</p>
                <button onClick={handleShow}>Subscribe Now</button>
            </div>
        </div>
        <div style={{display:show?'block':'none'}}>
        <div className="cform small_screen d-none d-lg-block" >
					<div className="footer_wrapper">
						<div className="ftitle">
							<h2>We are here <br/>for you anytime</h2>
						</div>
						<div className="fm_fld">
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className="custom-field  one fm_flddiv">
                <input type="text" id='_fname_l' placeholder=" " className="subinputfield"
                 {...register('_fname_l', { required: true })}/>
                <span className="placeholder">Name{errors._fname_l &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._fname_l && <p className='mt-1 important'>Name is a mandatory field</p>}
              </label>
               <label className="custom-field  one fm_flddiv">
                <input type="text" id='_phone_l' placeholder=" " className="subinputfield"
                {...register("_phone_l", {
                  required: true,
                    pattern:{
                      value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                    },
                })}/>
                <span className="placeholder">Contact Number{errors._phone_l &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._phone_l && <p className='mt-1 important'>Please enter a contact number</p>}
              </label>
              <label className="custom-field  one fm_flddiv">
                <input type="text" id='_email_l' placeholder=" " className="subinputfield"
                {...register("_email_l", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                })}/>
                <span className="placeholder">Email{errors._email_l &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._email_l && <p className='mt-1 important'>Please enter a valid email address</p>}
              </label>
             
							<p className='field_p'>
              <ReadMoreReact min={70}
                ideal={90}
                max={200}
                readMoreText="Read more" text={'By signing up to the Evig Electronics you accept that Evig Electronics can contact...........'} /> 
							</p>
							<button type="submit">Subscribe Now</button>
              </form>
						</div>
					</div>

				</div>
    <div className='d-lg-none large_screen' >
      <div className='row'>
        <div className='col-12 ftitle_mob' style={{textAlign:'center'}}>
          <h2>We are here <br/>for you anytime</h2>
        </div>
        <div className='col-12'>
          <div className="fm_fld_mob">
        <div className="fm_fld_mob">
        <form onSubmit={handleSubmit(onSubmit)}>
              <label className="custom-field  one fm_flddiv">
                <input type="text" id='_fname' placeholder=" " className="subinputfield"
                 {...register('_fname', { required: true })}/>
                <span className="placeholder">Name{errors._fname &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._fname && <p className='mt-1 important'>Name is a mandatory field</p>}
              </label>
               <label className="custom-field  one fm_flddiv">
                <input type="text" id='_phone' placeholder=" " className="subinputfield"
                {...register("_phone", {
                  required: true,
                    pattern:{
                      value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                    },
                })}/>
                <span className="placeholder">Contact Number{errors._phone &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._phone && <p className='mt-1 important'>Please enter a contact number</p>}
              </label>
              <label className="custom-field  one fm_flddiv">
                <input type="text" id='_email' placeholder=" " className="subinputfield"
                {...register("_email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                })}/>
                <span className="placeholder">Email{errors._email &&<span style={{color:'#CC0556',padding:5}}>*</span>}</span>
                {errors._email && <p className='mt-1 important'>Please enter a valid email address</p>}
              </label>
             
							<p className='field_p'>
              <ReadMoreReact min={70}
                ideal={90}
                max={200}
                readMoreText="Read more" text={'By signing up to the Evig Electronics you accept that Evig Electronics can contact...........'} /> 
							</p>
							<button type="submit">Subscribe Now</button>
              </form>
						</div>
						</div>            
        </div>
      </div>
    </div>
        </div>
       
       
        
        <Footer/>
        <Toaster />
    </div>
    
  )
}

export default Listing